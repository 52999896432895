import router from '@/router'
import store from '@/store'

const whiteList = ['Login', 'ChangePassword', 'Activate', 'RecoveryPassword']
const blackListForVendorUser = ['Logs', 'Vendors']

router.beforeEach(async (to, from, next) => {
  const route: string = to.name?.toString() ?? ''

  if (!whiteList.includes(route)) {
    await store.dispatch('user/getSelfData')
    await store.dispatch('user/getUserData')
  }

  const isAuthorized = store.getters['user/authorized']
  const userRole = store.getters['user/user'].role
  if (isAuthorized) {
    if (userRole === 'vendorUser' || userRole === 'shopAdmin') {
      if (blackListForVendorUser.includes(to.name as string)) {
        next({ name: 'Statistic' })
      } else {
        next()
      }
    } else {
      if (to.name === 'Login') {
        next({ name: 'Statistic' })
      } else {
        next()
      }
    }
  } else {
    if (whiteList.includes(to.name as string)) {
      next()
    } else {
      next({ name: 'Login' })
    }
  }
})
