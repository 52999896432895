import { Module, MutationAction, VuexModule } from 'vuex-module-decorators'
import { notificationsApi } from '@/api/notification.api'
import {
  NotificationsFiltersType,
  ResponseNotificationsType,
  VuexNotificationsCountType,
  VuexNotificationsType,
} from '@/types/notifications'

@Module({ stateFactory: true, namespaced: true })
export default class Notifications extends VuexModule {
  _notifications: ResponseNotificationsType = {} as ResponseNotificationsType
  _notificationsCount = 0

  get notificationsTotalCount(): number {
    return this._notificationsCount
  }

  get notificationsCount(): number {
    return this._notifications.count
  }

  get notificationsList(): ResponseNotificationsType {
    return this._notifications
  }

  @MutationAction({
    mutate: ['_notificationsCount'],
  })
  async getNotificationsCount(): Promise<VuexNotificationsCountType> {
    const [error, data] = await notificationsApi.getNotificationsCount()

    if (!error && data) {
      return {
        _notificationsCount: data.count,
      }
    } else {
      return {
        _notificationsCount: 0,
      }
    }
  }

  @MutationAction({
    mutate: ['_notifications'],
  })
  async getNotifications(
    params?: NotificationsFiltersType
  ): Promise<VuexNotificationsType> {
    const [error, data] = await notificationsApi.getNotifications(params)

    if (!error && data) {
      return {
        _notifications: data,
      }
    } else {
      return {
        _notifications: {},
      }
    }
  }
}
