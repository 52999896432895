import { Module, VuexModule, MutationAction } from 'vuex-module-decorators'
import { shopsAPI } from '@/api/shops.api'
import { ShopsListItemType, ShopsListType } from '@/types/shops'
import {
  PayloadListType,
  PayloadListWithQueryType,
  TableExportType,
} from '@/types/general'

@Module({ stateFactory: true, namespaced: true })
export default class ShopsModule extends VuexModule {
  _list: ShopsListItemType[] = []
  _total = 0
  _selected = 0
  _detail: ShopsListType | Record<string, any> = {}
  _exportShops: TableExportType | Record<string, any> = {}

  get shopsList() {
    return this._list
  }

  get shopsTotal() {
    return this._total
  }

  get selectedShops() {
    return this._selected
  }

  get shopsDetail() {
    return this._detail
  }

  get exportShops() {
    return this._exportShops
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getShopsList(payload?: PayloadListWithQueryType) {
    const [error, data] = await shopsAPI.shopsList(
      payload?.params?.limit,
      payload?.params?.offset,
      payload?.params?.filter,
      payload?.params?.order,
      payload?.query?.isTop,
      payload?.query?.topLimit
    )

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      console.log(error)
      return {
        _list: [],
        _total: 0,
        _selected: null,
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getShopsDetail(id: number) {
    const [error, data] = await shopsAPI.shopsDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      console.log(error)
      return {
        _detail: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getShopsDetailByRemoteId(id: number) {
    const [error, data] = await shopsAPI.shopsDetailByRemoteId(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      console.log(error)
      return {
        _detail: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_exportShops'],
  })
  async getExportShops(payload?: PayloadListType) {
    const [error, data] = await shopsAPI.shopsExport(
      payload?.filter,
      payload?.order,
      payload?.query?.isTop,
      payload?.query?.topLimit
    )

    if (!error && data) {
      return {
        _exportShops: data,
      }
    } else {
      console.log(error)
      return {
        _exportShops: {},
      }
    }
  }
}
