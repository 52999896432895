import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import { TableExportType, PayloadListType } from '@/types/general'
import {
  ActivationsListType,
  reportsCreateResponseType,
  reportsCreateType,
  ReportsListType,
  UsersListType,
  VendorsListType,
} from '@/types/transactions'

export default class TransactionsApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/'
          : '/api/',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async vendorsList(
    params: PayloadListType
  ): Promise<AxiosResponse<VendorsListType>> {
    return await this.axiosCall<VendorsListType>({
      method: 'get',
      url: `transactions/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async vendorsExport(
    params: PayloadListType
  ): Promise<AxiosResponse<TableExportType>> {
    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `transactions/table/export/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async usersList(
    params: PayloadListType
  ): Promise<AxiosResponse<UsersListType>> {
    return await this.axiosCall<UsersListType>({
      method: 'get',
      url: `UserAccountTransaction/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async usersExport(
    params: PayloadListType
  ): Promise<AxiosResponse<TableExportType>> {
    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `UserAccountTransaction/table/export/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async activationsList(
    params: PayloadListType
  ): Promise<AxiosResponse<ActivationsListType>> {
    return await this.axiosCall<ActivationsListType>({
      method: 'get',
      url: `UserActivateTransaction/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async activationsExport(
    params: PayloadListType
  ): Promise<AxiosResponse<TableExportType>> {
    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `UserActivateTransaction/table/export/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async reportsList(
    params: PayloadListType
  ): Promise<AxiosResponse<ReportsListType>> {
    return await this.axiosCall<ReportsListType>({
      method: 'get',
      url: `TransactionReports/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async reportsExport(
    params: PayloadListType
  ): Promise<AxiosResponse<TableExportType>> {
    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `TransactionReports/table/export/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async reportsCreate(
    data: reportsCreateType
  ): Promise<AxiosResponse<reportsCreateResponseType>> {
    const form = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      form.append(key, value)
    })

    return await this.axiosCall<reportsCreateResponseType>({
      method: 'post',
      url: 'TransactionReports/create',
      data: form,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

export const transactionsAPI = new TransactionsApiService()
