












































































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
import { UserResponseType } from '@/types/user'

@Component
export default class Sidebar extends Vue {
  @Prop({ type: Boolean })
  showNotifications!: boolean

  @Prop({ type: Number, default: 0 })
  notificationsCount!: number

  get user(): UserResponseType {
    return this.$store.getters['user/user']
  }

  get userPhoto(): string {
    return process.env.VUE_APP_BASE_URI + this.user.photo
  }

  get isVendorUser(): boolean {
    return this.user.role === 'vendorUser'
  }

  get isShopAdmin(): boolean {
    return this.user.role === 'shopAdmin'
  }

  get isAdmin(): boolean {
    return this.user.role === 'admin'
  }

  handleShowNotifications(): void {
    this.$emit('show-notifications')
  }

  async handleLogOut(): Promise<void> {
    await this.$store.dispatch('user/logout')
    this.$router.push({ name: 'Login' })
  }
}
