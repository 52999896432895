import { AxiosRequestConfig } from 'axios'
import { AxiosResponse, AxiosService } from '@/plugins/axios'
import { UserChangeType } from '@/types/user'
import { PayloadListType } from '@/types/general'
import {
  UsersChangePasswordPayloadType,
  UsersListItemType,
  UsersListType,
  AddUserForm,
} from '@/types/users'

export default class UsersApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/users'
          : '/api/users',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async usersChange(data: UserChangeType): Promise<AxiosResponse<string>> {
    const form = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      form.append(key, value)
    })

    return this.axiosCall<string>({
      method: 'post',
      url: `/update/${data.id}`,
      data: form,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async usersList(
    params: PayloadListType
  ): Promise<AxiosResponse<UsersListType>> {
    return await this.axiosCall<UsersListType>({
      method: 'get',
      url: `/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async usersDetail(id: number): Promise<AxiosResponse<UsersListItemType>> {
    return await this.axiosCall<UsersListItemType>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }

  async usersChangePassword(
    payload: UsersChangePasswordPayloadType
  ): Promise<AxiosResponse<UsersChangePasswordPayloadType>> {
    return await this.axiosCall<UsersChangePasswordPayloadType>({
      method: 'post',
      url: `/update_password/${payload.id}`,
      data: {
        CURRENT_PASSWORD: payload.currentPassword,
        PASSWORD: payload.newPassword,
        CONFIRM_PASSWORD: payload.newPassword,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async userCreate(
    payload: FormData
  ): Promise<AxiosResponse<boolean | number>> {
    return await this.axiosCall<boolean | number>({
      method: 'post',
      url: '/create',
      data: payload,
    })
  }

  async addNewUser(
    data: AddUserForm
  ): Promise<AxiosResponse<boolean | number>> {
    return await this.axiosCall<boolean | number>({
      method: 'post',
      url: '/add_user_form',
      data,
    })
  }
}

export const usersAPI = new UsersApiService()
