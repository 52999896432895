import Vue from 'vue'

function calculateSizes(screen) {
  if (screen >= 1170) return 'md'
  else if (screen >= 768) return 'sm'
  else if (screen >= 320) return 'xs'
  else return ''
}

const screen = Vue.observable({
  width: window.innerWidth,
  size: calculateSizes(window.innerWidth),
})

window.addEventListener('resize', () => {
  screen.width = window.innerWidth
  screen.size = calculateSizes(window.innerWidth)
})

export default screen
