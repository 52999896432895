import {
  Module,
  VuexModule,
  MutationAction,
  Action,
  Mutation,
} from 'vuex-module-decorators'
import { filesAPI } from '@/api/files.api'

@Module({ stateFactory: true, namespaced: true })
export default class FilesModule extends VuexModule {
  _uploadedFiles: string[] = []
  _uploadedFile = ''

  get uploadedFiles() {
    return this._uploadedFiles
  }

  get uploadedFile() {
    return this._uploadedFile
  }

  @Mutation
  pushFile(file: string): void {
    this._uploadedFiles.push(file)
  }

  @Mutation
  deleteFiles(index: number): void {
    this._uploadedFiles.splice(index, 1)
  }

  @Mutation
  clearFiles(): void {
    this._uploadedFiles = []
  }

  @Action({ commit: 'pushFile' })
  async uploadFiles(file: File) {
    const [error, data] = await filesAPI.filesUpload(file)

    if (!error && data) {
      return data
    } else {
      console.log(error)
    }
  }

  @Mutation
  deleteFile(): void {
    this._uploadedFile = ''
  }

  @Mutation
  setFile(file: string): void {
    this._uploadedFile = file
  }

  @Action({ commit: 'setFile' })
  async uploadFile(file: File) {
    const [error, data] = await filesAPI.filesUpload(file)

    if (!error && data) {
      return data
    } else {
      console.log(error)
    }
  }
}
