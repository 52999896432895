import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import { TableExportType, PayloadListType } from '@/types/general'
import {
  OrderReportsCreateResponseType,
  OrderReportsCreateType,
  OrderReportsListItemType,
  OrderReportsListType,
} from '@/types/orderReports'

export default class OrderReportsApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/orderReport'
          : '/api/orderReport',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async orderReportsList(
    params: PayloadListType
  ): Promise<AxiosResponse<OrderReportsListType>> {
    return await this.axiosCall<OrderReportsListType>({
      method: 'get',
      url: `/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async orderReportsDetail(
    id: number
  ): Promise<AxiosResponse<OrderReportsListItemType>> {
    return await this.axiosCall<OrderReportsListItemType>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }

  async orderReportsExport(
    params: PayloadListType
  ): Promise<AxiosResponse<TableExportType>> {
    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `/table/export/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async ordersReportsCreate(
    data: OrderReportsCreateType
  ): Promise<AxiosResponse<OrderReportsCreateResponseType>> {
    const form = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      form.append(key, value)
    })

    return await this.axiosCall<OrderReportsCreateResponseType>({
      method: 'post',
      url: '/create',
      data: form,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }
}

export const orderReportsAPI = new OrderReportsApiService()
