import { AxiosRequestConfig } from 'axios'
import { AxiosResponse, AxiosService } from '@/plugins/axios'
import {
  ActivateUserType,
  UserResponseType,
  RecoveryPassword,
  ChangePassword,
} from '@/types/user'

export default class UserApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/user'
          : '/api/user',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async loginUser(
    login: string,
    password: string
  ): Promise<AxiosResponse<Boolean>> {
    return this.axiosCall<Boolean>({
      method: 'post',
      url: '/login',
      data: {
        login,
        password,
      },
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async selfUser() {
    return this.axiosCall<Boolean>({
      method: 'get',
      url: '/self',
    })
  }

  async user(): Promise<AxiosResponse<UserResponseType>> {
    return this.axiosCall<UserResponseType>({
      method: 'get',
      url: '/',
    })
  }

  async userBlock(id: number, reason: string): Promise<AxiosResponse<boolean>> {
    return await this.axiosCall<boolean>({
      method: 'post',
      url: `/block/${id}`,
      data: {
        reason,
      },
    })
  }

  async userUnblock(id: number): Promise<AxiosResponse<boolean>> {
    return await this.axiosCall<boolean>({
      method: 'post',
      url: `/unblock/${id}`,
    })
  }

  async sendUserInfo(id: number): Promise<AxiosResponse<boolean>> {
    return await this.axiosCall<boolean>({
      method: 'post',
      url: `/sendUserInfo/${id}`,
    })
  }

  async activateUser(
    payload: ActivateUserType
  ): Promise<AxiosResponse<boolean>> {
    return await this.axiosCall<boolean>({
      method: 'post',
      url: `/activate/${payload.userId}`,
      data: {
        password: payload.password,
        password_confirm: payload.passwordConfirm,
        confirmCode: payload.confirmCode,
      },
    })
  }

  async recoveryPassword(
    data: RecoveryPassword
  ): Promise<AxiosResponse<boolean>> {
    return await this.axiosCall<boolean>({
      method: 'post',
      url: '/passwordReset',
      data,
    })
  }

  async changePassword(data: ChangePassword): Promise<AxiosResponse<boolean>> {
    return await this.axiosCall<boolean>({
      method: 'post',
      url: '/changePassword',
      data,
    })
  }
}

export const userAPI = new UserApiService()
