














































import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { DcSelect } from 'dc-ui-library/.dist/types/packages/components'

@Component
export default class SelectCommon extends Vue {
  @Model('change')
  value!: string

  @Prop({ type: String })
  label?: string

  @Prop({ type: Array })
  options?: []

  @Prop({ type: Boolean, default: false })
  multiple!: boolean

  @Prop({ type: Boolean, default: false })
  collapse!: boolean

  @Prop({ type: Number })
  skippedValue?: number

  @Prop({ type: Boolean, default: false })
  custom!: boolean

  @Prop({ type: Boolean, default: true })
  dropdown!: boolean

  @Prop({ type: Boolean, default: false })
  searchable!: boolean

  focus = false

  $refs!: {
    select: DcSelect
  }

  handleSearch(value: string): void {
    this.$emit('search-value', value)
  }

  handleOptionSelect(option: String): void {
    this.$emit('change', option)
  }

  handleClickOnLabel(): void {
    const input = this.$refs.select?.$el as HTMLLabelElement
    if (input) {
      input.focus()
    }
    this.focus = true
  }

  //TODO Доделать проверку
}
