import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import {
  EditPromotionType,
  PromotionsCreateType,
  PromotionsItemListType,
  PromotionsListType,
  PromotionsRulesType,
  setCommentType,
  ChangePromotionStatusType,
} from '@/types/promotions'
import {
  CountListType,
  TableExportType,
  PayloadListType,
} from '@/types/general'

export default class PromotionsApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/activities'
          : '/api/activities',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async promotionsList(
    params: PayloadListType
  ): Promise<AxiosResponse<PromotionsListType>> {
    return await this.axiosCall<PromotionsListType>({
      method: 'get',
      url: `/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async promotionsDetail(
    id: number
  ): Promise<AxiosResponse<PromotionsItemListType>> {
    return await this.axiosCall<PromotionsItemListType>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }

  async promotionsCount(
    params: PayloadListType
  ): Promise<AxiosResponse<CountListType[]>> {
    return await this.axiosCall<CountListType[]>({
      method: 'get',
      url: `/count/${JSON.stringify(params) ? JSON.stringify(params) : ''}`,
    })
  }

  async promotionsExport(
    params: PayloadListType
  ): Promise<AxiosResponse<TableExportType>> {
    return this.axiosCall<TableExportType>({
      method: 'post',
      url: `/table/export/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async promotionsCreate(
    data: PromotionsCreateType
  ): Promise<AxiosResponse<string>> {
    const form = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      form.append(key, value as string | Blob)
    })

    return this.axiosCall<string>({
      method: 'post',
      url: '/create',
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  async getActivitiesRules(): Promise<AxiosResponse<PromotionsRulesType>> {
    return this.axiosCall<PromotionsRulesType>({
      method: 'get',
      url: '/rules',
    })
  }

  async promotionsUpdate(
    data: EditPromotionType
  ): Promise<AxiosResponse<string>> {
    const form = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      form.append(key, value as string | Blob)
    })

    return this.axiosCall<string>({
      method: 'post',
      url: `update/${data.id}`,
      data: form,
    })
  }

  async setOnModerationStatus(id: string): Promise<AxiosResponse<string>> {
    return this.axiosCall({
      method: 'post',
      url: `/setModeration/${id}`,
    })
  }

  async setNew(
    data: ChangePromotionStatusType
  ): Promise<AxiosResponse<string>> {
    return this.axiosCall({
      method: 'post',
      url: '/setNew',
      data,
    })
  }

  async setActivate(id: string): Promise<AxiosResponse<string>> {
    return this.axiosCall({
      method: 'post',
      url: `/setActive/${id}`,
    })
  }

  async setBlock(
    data: ChangePromotionStatusType
  ): Promise<AxiosResponse<string>> {
    return this.axiosCall({
      method: 'post',
      url: `/setBlocked/`,
      data,
    })
  }

  async setComment(data: setCommentType): Promise<AxiosResponse<string>> {
    return this.axiosCall({
      method: 'post',
      url: '/setComment',
      data: data,
    })
  }
}

export const promotionsAPI = new PromotionsApiService()
