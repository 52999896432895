import { AxiosRequestConfig } from 'axios'
import { AxiosResponse, AxiosService } from '@/plugins/axios'
import {
  NotificationsCountType,
  NotificationsFiltersType,
  NotificationsReadType,
  ResponseNotificationsType,
} from '@/types/notifications'

class NotificationApi {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/notification'
          : '/api/notification',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async getNotificationsCount() {
    return this.axiosCall<NotificationsCountType>({
      method: 'get',
      url: '/new_count',
    })
  }

  async getNotifications(params?: NotificationsFiltersType) {
    return this.axiosCall<ResponseNotificationsType>({
      method: 'get',
      url: '/find',
      params,
    })
  }

  async readNotifications(ids: number[]) {
    return this.axiosCall<NotificationsReadType>({
      method: 'post',
      url: '/read',
      data: {
        ids,
      },
    })
  }
}

export const notificationsApi = new NotificationApi()
