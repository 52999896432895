import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import {
  ResponseShopType,
  ShopsListItemType,
  ShopsListType,
} from '@/types/shops'
import { TableExportType } from '@/types/general'
import { SelectType } from '@/types/promotions'

export default class ShopsApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/shop'
          : '/api/shop',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async shopsList(
    limit?: number,
    offset?: number,
    filter?: Record<string, any>,
    order?: object,
    isTop?: number,
    topLimit?: number
  ): Promise<AxiosResponse<ShopsListType>> {
    const params = JSON.stringify({
      limit,
      offset,
      filter,
      order,
    })

    const query = {
      isTop,
      topLimit,
    }

    return await this.axiosCall<ShopsListType>({
      method: 'get',
      url: `/query_full/${params}`,
      params: query,
    })
  }

  async shopsDetail(id: number): Promise<AxiosResponse<ShopsListItemType>> {
    return await this.axiosCall<ShopsListItemType>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }

  async shopsDetailByRemoteId(
    id: number
  ): Promise<AxiosResponse<ShopsListItemType>> {
    return await this.axiosCall<ShopsListItemType>({
      method: 'get',
      url: `/detail_by_remote_id/${id}`,
    })
  }

  async shopsExport(
    filter?: Record<string, any>,
    order?: object,
    isTop?: number,
    topLimit?: number
  ): Promise<AxiosResponse<TableExportType>> {
    const params = JSON.stringify({
      filter,
      order,
    })

    const query = {
      isTop,
      topLimit,
    }

    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `/table/export/${params}`,
      params: query,
    })
  }

  async getCity(data: string): Promise<AxiosResponse<SelectType[]>> {
    return this.axiosCall<SelectType[]>({
      method: 'post',
      url: '/city',
      data: {
        filter: data,
      },
    })
  }

  async getCountry(data: string): Promise<AxiosResponse<SelectType[]>> {
    return this.axiosCall<SelectType[]>({
      method: 'post',
      url: '/country',
      data: {
        filter: data,
      },
    })
  }

  async getRegion(data: string): Promise<AxiosResponse<SelectType[]>> {
    return this.axiosCall<SelectType[]>({
      method: 'post',
      url: '/region',
      data: {
        filter: data,
      },
    })
  }

  async getShops(data: string): Promise<AxiosResponse<SelectType[]>> {
    return await this.axiosCall<SelectType[]>({
      method: 'post',
      url: '/shop',
      data: {
        filter: data,
      },
    })
  }

  async getShopsByName(data: string): Promise<AxiosResponse<SelectType[]>> {
    return await this.axiosCall<SelectType[]>({
      method: 'post',
      url: '/shop_by_name',
      data: {
        filter: data,
      },
    })
  }

  async getShop(code: string): Promise<AxiosResponse<ResponseShopType>> {
    return await this.axiosCall<ResponseShopType>({
      method: 'get',
      url: `/query/${code}`,
    })
  }
}

export const shopsAPI = new ShopsApiService()
