






































import { Component, Prop, Vue } from 'vue-property-decorator'
import { NotificationItemType } from '@/types/notifications'

@Component
export default class Notifications extends Vue {
  @Prop({ type: Array })
  notifications!: NotificationItemType[]

  @Prop({ type: Boolean, default: false })
  loading!: boolean

  checkStatus(status: string): string {
    switch (status) {
      case 'codesAdd': {
        return 'bg-primary'
      }

      case 'vendorUsersAdded': {
        return 'bg-primary'
      }

      case 'vendorUsersBlocked': {
        return 'bg-danger'
      }

      case 'vendorUsersAddBalance': {
        return 'bg-primary'
      }

      case 'activityAdd': {
        return 'bg-primary'
      }

      case 'activityToModeration': {
        return 'bg-warning'
      }

      case 'activityUpdate': {
        return 'bg-primary'
      }

      case 'activityActivated': {
        return 'bg-success'
      }

      case 'activityBlocked': {
        return 'bg-danger'
      }

      case 'activityRequireChange': {
        return 'bg-purple'
      }

      case 'activityClosed': {
        return 'bg-gray'
      }

      case 'tasksAdd': {
        return 'bg-success'
      }

      case 'tasksActivated': {
        return 'bg-success'
      }

      case 'tasksAnswer': {
        return 'bg-success'
      }

      case 'tasksClosed': {
        return 'bg-gray'
      }

      case 'remoteUsersAdd': {
        return 'bg-primary'
      }

      default:
        return ''
    }
  }
}
