

























































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class MenuMobile extends Vue {
  @Prop({ type: Boolean, default: false })
  showMenu!: boolean

  handleShowMenu(): void {
    this.$emit('show-menu')
  }
}
