





























































import { Component, Vue } from 'vue-property-decorator'
import { UserResponseType } from '@/types/user'

@Component
export default class MenuMore extends Vue {
  get user(): UserResponseType {
    return this.$store.getters['user/user']
  }

  get isShopAdmin(): boolean {
    return this.user.role === 'shopAdmin'
  }

  get isAdmin(): boolean {
    return this.user.role === 'admin'
  }

  get isVendorUser(): boolean {
    return this.user.role === 'vendorUser'
  }

  handleCloseMenu(): void {
    this.$emit('close-menu')
  }

  async logOut(): Promise<void> {
    await this.$store.dispatch('user/logout')

    this.$router.push({ name: 'Login' })

    this.handleCloseMenu()
  }
}
