import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import { ReportsListItemType, ReportsListType } from '@/types/reports'
import { PayloadListType } from '@/types/general'

export default class ReportsApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/reports'
          : '/api/reports',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async reportsList(
    params: PayloadListType
  ): Promise<AxiosResponse<ReportsListType>> {
    return await this.axiosCall<ReportsListType>({
      method: 'get',
      url: `/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async reportsDetail(id: number): Promise<AxiosResponse<ReportsListItemType>> {
    return await this.axiosCall<ReportsListItemType>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }
}

export const reportsAPI = new ReportsApiService()
