import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import DcUiLibrary from 'dc-ui-library'
import '@/permissions'
import '@/styles/main.scss'
import screen from '@/utils/screen-variables'
import Maska from 'maska'

import { requireSvg } from '@/plugins/requireSvg'

requireSvg()

import {
  Table,
  TableColumn,
  Badge,
  Steps,
  Step,
  RadioGroup,
  RadioButton,
  Upload,
  Switch,
  Loading,
  Collapse,
  CollapseItem,
  Form,
  FormItem,
  Input,
  Checkbox,
} from 'element-ui'

import lang from 'element-ui/lib/locale/lang/ru-RU'
import locale from 'element-ui/lib/locale'

locale.use(lang)
Vue.component(Table.name, Table)
Vue.component(TableColumn.name, TableColumn)
Vue.component(Badge.name, Badge)
Vue.component(Steps.name, Steps)
Vue.component(Step.name, Step)
Vue.component(RadioGroup.name, RadioGroup)
Vue.component(RadioButton.name, RadioButton)
Vue.component(Upload.name, Upload)
Vue.component(Switch.name, Switch)
Vue.use(Loading.directive)
Vue.component(Collapse.name, Collapse)
Vue.component(CollapseItem.name, CollapseItem)
Vue.component(Form.name, Form)
Vue.component(FormItem.name, FormItem)
Vue.component(Input.name, Input)
Vue.component(Checkbox.name, Checkbox)

Vue.use(DcUiLibrary)
Vue.use(Maska)

Vue.config.productionTip = false

Vue.prototype.$screen = screen

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
