import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import { TableExportType, PayloadListType } from '@/types/general'
import { LogsListItemType, LogsListType } from '@/types/logs'

export default class LogsApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/log'
          : '/api/log',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async logsList(
    params: PayloadListType
  ): Promise<AxiosResponse<LogsListType>> {
    return await this.axiosCall<LogsListType>({
      method: 'get',
      url: `/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async logsDetail(id: number): Promise<AxiosResponse<LogsListItemType>> {
    return await this.axiosCall<LogsListItemType>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }

  async logsExport(): Promise<AxiosResponse<TableExportType>> {
    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: '/table/export/',
    })
  }
}

export const logsAPI = new LogsApiService()
