import { Module, VuexModule, MutationAction } from 'vuex-module-decorators'
import { PayloadListType, TableExportType } from '@/types/general'
import { AccrualsListItemType } from '@/types/accruals'
import { accrualsAPI } from '@/api/accruals.api'

@Module({ stateFactory: true, namespaced: true })
export default class AccrualsModule extends VuexModule {
  _list: AccrualsListItemType[] = []
  _total = 0
  _selected = 0
  _detail: AccrualsListItemType | Record<string, any> = {}
  _exportAccruals: TableExportType | Record<string, any> = {}

  get accrualsList() {
    return this._list
  }

  get accrualsTotal() {
    return this._total
  }

  get selectedAccruals() {
    return this._selected
  }

  get accrualsDetail() {
    return this._detail
  }

  get exportAccruals() {
    return this._exportAccruals
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getAccrualsList(payload: PayloadListType) {
    const [error, data] = await accrualsAPI.accrualsList(payload)

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      console.log(error)
      return {
        _list: [],
        _total: 0,
        _selected: null,
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getAccrualsDetail(id: number) {
    const [error, data] = await accrualsAPI.accrualsDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      console.log(error)
      return {
        _detail: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_exportAccruals'],
  })
  async getExportAccruals(payload: PayloadListType) {
    const [error, data] = await accrualsAPI.accrualsExport(payload)

    if (!error && data) {
      return {
        _exportAccruals: data,
      }
    } else {
      console.log(error)
      return {
        _exportAccruals: {},
      }
    }
  }
}
