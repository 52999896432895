






















import { Component, Model, Prop, Vue } from 'vue-property-decorator'
import { DcDatepicker } from 'dc-ui-library/.dist/types/packages/components'

@Component
export default class DatePickerCommon extends Vue {
  $refs!: {
    datepicker: DcDatepicker
  }

  @Model('change')
  value!: string

  @Prop({ type: String })
  label?: string

  @Prop({ type: String, default: 'DD.MM.YYYY' })
  format!: string

  @Prop({ type: String, default: 'date' })
  type!: string

  focus = false

  handleDatePickerChange(event: string): void {
    this.$emit('change', event)
    this.focus = !this.focus
  }

  handleLabelClick(): void {
    const input = this.$refs.datepicker.$el.children[0].children[0]
      .children[1] as HTMLInputElement
    input.focus()
  }
}
