import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import {
  AllVendorsType,
  VendorsCreateType,
  VendorsListItemType,
  VendorsListType,
} from '@/types/vendors'
import { TableExportType, PayloadListType } from '@/types/general'
import { SelectType } from '@/types/promotions'

export default class VendorsApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/vendor'
          : '/api/vendor',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async vendorsList(
    limit?: number,
    offset?: number,
    filter?: Record<string, any>,
    order?: object,
    isTop?: number,
    topLimit?: number
  ): Promise<AxiosResponse<VendorsListType>> {
    const params = JSON.stringify({
      limit,
      offset,
      filter,
      order,
    })

    const query = {
      isTop,
      topLimit,
    }

    return await this.axiosCall<VendorsListType>({
      method: 'get',
      url: `/query_full/${params}`,
      params: query,
    })
  }

  async vendorsDetail(id: number): Promise<AxiosResponse<VendorsListItemType>> {
    return await this.axiosCall<VendorsListItemType>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }

  async vendorsExport(
    filter?: Record<string, any>,
    order?: object,
    isTop?: number,
    topLimit?: number
  ): Promise<AxiosResponse<TableExportType>> {
    const params = JSON.stringify({
      filter,
      order,
    })

    const query = {
      isTop,
      topLimit,
    }

    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `/table/export/${params}`,
      params: query,
    })
  }

  async vendorsCreate(data: VendorsCreateType): Promise<AxiosResponse<string>> {
    const form = new FormData()
    Object.entries(data).forEach(([key, value]) => {
      form.append(key, value)
    })

    return await this.axiosCall<string>({
      method: 'post',
      url: '/create/',
      data: form,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async getAllVendors(): Promise<AxiosResponse<AllVendorsType>> {
    return await this.axiosCall<AllVendorsType>({
      method: 'get',
      url: '/get_all/',
    })
  }

  async vendorUpdateBalance(payload: {
    id: string
    text: string
  }): Promise<AxiosResponse<string>> {
    return await this.axiosCall<string>({
      method: 'post',
      url: `/updateBalance/${JSON.stringify(payload)}`,
      headers: {
        'Content-Type': 'application/json',
      },
    })
  }

  async getVendorPrefix(data: string): Promise<AxiosResponse<SelectType[]>> {
    return this.axiosCall<SelectType[]>({
      method: 'post',
      url: '/prefix',
      data: {
        filter: data,
      },
    })
  }
}

export const vendorsAPI = new VendorsApiService()
