import { Module, VuexModule, MutationAction } from 'vuex-module-decorators'
import { reportsAPI } from '@/api/reports.api'
import { ReportsListItemType } from '@/types/reports'
import { PayloadListType } from '@/types/general'

@Module({ stateFactory: true, namespaced: true })
export default class ReportsModule extends VuexModule {
  _list: ReportsListItemType[] = []
  _total = 0
  _selected = 0
  _detail: ReportsListItemType | Record<string, any> = {}

  get reportsList() {
    return this._list
  }

  get reportsTotal() {
    return this._total
  }

  get selectedReports() {
    return this._selected
  }

  get reportsDetail() {
    return this._detail
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getReportsList(payload: PayloadListType) {
    const [error, data] = await reportsAPI.reportsList(payload)

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      console.log(error)
      return {
        _list: [],
        _total: 0,
        _selected: 0,
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getReportsDetail(id: number) {
    const [error, data] = await reportsAPI.reportsDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      console.log(error)
      return {
        _detail: {},
      }
    }
  }
}
