import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'

export default class FilesApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/file'
          : '/api/file',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async filesUpload(file: File): Promise<AxiosResponse<string>> {
    const form = new FormData()
    form.append('file', file)

    return await this.axiosCall<string>({
      method: 'post',
      url: '/upload/',
      data: form,
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }
}

export const filesAPI = new FilesApiService()
