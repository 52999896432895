import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import {
  CreateTaskDataType,
  TasksItemListType,
  TasksListType,
} from '@/types/tasks'
import { CountListType, PayloadListType } from '@/types/general'

export default class TasksApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/task'
          : '/api/task',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async tasksList(
    params: PayloadListType
  ): Promise<AxiosResponse<TasksListType>> {
    return await this.axiosCall<TasksListType>({
      method: 'get',
      url: `/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async tasksCreate(data: CreateTaskDataType): Promise<AxiosResponse<string>> {
    return await this.axiosCall<string>({
      method: 'post',
      url: `/create`,
      data,
    })
  }

  async tasksCount(
    params: PayloadListType
  ): Promise<AxiosResponse<CountListType[]>> {
    return await this.axiosCall<CountListType[]>({
      method: 'get',
      url: `/count/${JSON.stringify(params) ? JSON.stringify(params) : ''}`,
    })
  }

  async tasksDetail(id: number): Promise<AxiosResponse<TasksItemListType>> {
    return await this.axiosCall<TasksItemListType>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }

  async tasksExport(params: PayloadListType): Promise<AxiosResponse<any>> {
    return await this.axiosCall<any>({
      method: 'get',
      url: `/table/export/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async activeTask(id: string): Promise<AxiosResponse<boolean>> {
    return await this.axiosCall<boolean>({
      method: 'post',
      url: `setActive/${id}`,
    })
  }

  async closeTask(id: string): Promise<AxiosResponse<string>> {
    return await this.axiosCall<string>({
      method: 'post',
      url: `setClosed/${id}`,
    })
  }
}

export const tasksAPI = new TasksApiService()
