import {
  Module,
  VuexModule,
  MutationAction,
  Action,
} from 'vuex-module-decorators'
import { promotionsAPI } from '@/api/promotions.api'
import {
  PromotionsCreateType,
  PromotionsItemListType,
} from '@/types/promotions'
import {
  CountListType,
  PayloadListType,
  TableExportType,
} from '@/types/general'

@Module({ stateFactory: true, namespaced: true })
export default class PromotionsModule extends VuexModule {
  _list: PromotionsItemListType[] = []
  _total = 0
  _selected = 0
  _detail: PromotionsItemListType | Record<string, any> = {}
  _count: CountListType[] = []
  _exportPromotions: TableExportType | Record<string, any> = {}
  _activitiesRules: string[] = []

  get promotionsList() {
    return this._list
  }

  get promotionsTotal() {
    return this._total
  }

  get selectedPromotions() {
    return this._selected
  }

  get promotionsDetail() {
    return this._detail
  }

  get promotionsCount() {
    return this._count
  }

  get exportPromotions() {
    return this._exportPromotions
  }

  get activitiesRules() {
    return this._activitiesRules
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getPromotionsList(payload: PayloadListType) {
    const [error, data] = await promotionsAPI.promotionsList(payload)

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      console.log(error)
      return {
        _list: [],
        _total: 0,
        _selected: 0,
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getPromotionsDetail(id: number) {
    const [error, data] = await promotionsAPI.promotionsDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      console.log(error)
      return {
        _detail: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_count'],
  })
  async getPromotionsCount(payload: PayloadListType) {
    const [error, data] = await promotionsAPI.promotionsCount(payload)

    if (!error && data) {
      return {
        _count: data,
      }
    } else {
      console.log(error)
      return {
        _count: [],
      }
    }
  }

  @MutationAction({
    mutate: ['_exportPromotions'],
  })
  async getExportPromotions(payload: PayloadListType) {
    const [error, data] = await promotionsAPI.promotionsExport(payload)

    if (!error && data) {
      return {
        _exportPromotions: data,
      }
    } else {
      console.log(error)
      return {
        _exportPromotions: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_activitiesRules'],
  })
  async getActivitiesRules() {
    const [error, data] = await promotionsAPI.getActivitiesRules()

    if (!error && data) {
      return {
        _activitiesRules: data,
      }
    } else {
      return {
        _activitiesRules: [],
      }
    }
  }
}
