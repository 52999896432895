import { Module, VuexModule, MutationAction } from 'vuex-module-decorators'
import { PayloadListType } from '@/types/general'
import { AccountsListItemType } from '@/types/accounts'
import { accountsAPI } from '@/api/accounts.api'

@Module({ stateFactory: true, namespaced: true })
export default class AccountsModule extends VuexModule {
  _list: AccountsListItemType[] = []
  _total = 0
  _selected = 0

  get accountsList() {
    return this._list
  }

  get accountsTotal() {
    return this._total
  }

  get selectedAccounts() {
    return this._selected
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getAccountsList(payload: PayloadListType) {
    const [error, data] = await accountsAPI.accountsList(payload)

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      console.log(error)
      return {
        _list: [],
        _total: 0,
        _selected: null,
      }
    }
  }
}
