



































































































import { Vue, Component, Watch } from 'vue-property-decorator'
import Sidebar from '@/components/layout/Sidebar.vue'
import Drawer from '@/components/common/Drawer.vue'
import Notifications from '@/components/notification/Notifications.vue'
import HeaderMobile from '@/components/mobile/layout/HeaderMobile.vue'
import MenuMobile from '@/components/mobile/layout/MenuMobile.vue'
import MenuMore from '@/components/mobile/menu/MenuMore.vue'
import SelectCommon from '@/components/common/SelectCommon.vue'
import DatePickerCommon from '@/components/common/DatePickerCommon.vue'
import { notificationsApi } from '@/api/notification.api'
import {
  NotificationItemType,
  NotificationsFiltersType,
  ResponseNotificationsType,
} from '@/types/notifications'

@Component({
  components: {
    DatePickerCommon,
    SelectCommon,
    MenuMore,
    MenuMobile,
    HeaderMobile,
    Sidebar,
    Drawer,
    Notifications,
  },
})
export default class Layout extends Vue {
  @Watch('$route')
  changePageCheck(): void {
    if (this.showNotifications) {
      this.showNotifications = false
    }
  }

  filterOptions = [
    {
      label: 'Все',
      value: '',
    },
    {
      label: 'Создан массив кодов',
      value: 'codesAdd',
    },
    {
      label: 'Новый субаккаунт',
      value: 'vendorUsersAdded',
    },
    {
      label: 'Заблокирован субаккаунт',
      value: 'vendorUsersBlocked',
    },
    {
      label: 'Пополнен баланс',
      value: 'vendorUsersAddBalance',
    },
    {
      label: 'Создана акция',
      value: 'activityAdd',
    },
    {
      label: 'Акция на модерации',
      value: 'activityToModeration',
    },
    {
      label: 'Акция отредактирована',
      value: 'activityUpdate',
    },
    {
      label: 'Акция активирована',
      value: 'activityActivated',
    },
    {
      label: 'Акция заблокирована',
      value: 'activityBlocked',
    },
    {
      label: 'Акция требует изменений',
      value: 'activityRequireChange',
    },
    {
      label: 'Акция закрыта',
      value: 'activityClosed',
    },
    {
      label: 'Добавлена задача',
      value: 'tasksAdd',
    },
    {
      label: 'Задача активирована',
      value: 'tasksActivated',
    },
    {
      label: 'Задача ответ',
      value: 'tasksAnswer',
    },
    {
      label: 'Задача закрыта',
      value: 'tasksClosed',
    },
    {
      label: 'Добавлен участник',
      value: 'remoteUsersAdd',
    },
  ]

  filter: NotificationsFiltersType = {
    offset: 0,
    limit: 20,
    dateFrom: '',
    dateTo: '',
    type: '',
  }

  showNotifications = false
  notificationsLoading = false
  showMenu = false

  get notificationsCount(): number {
    return this.$store.getters['notifications/notificationsCount']
  }

  get notificationsTotalCount(): number {
    return this.$store.getters['notifications/notificationsTotalCount']
  }

  get notifications(): ResponseNotificationsType {
    return this.$store.getters['notifications/notificationsList']
  }

  handleShowMenu(): void {
    this.showMenu = !this.showMenu
  }

  async readNotifications(): Promise<void> {
    const ids = this.notifications.items.map(
      (notification: NotificationItemType) => notification.id
    )

    const [error] = await notificationsApi.readNotifications(ids)

    if (!error) {
      await this.$store.dispatch('notifications/getNotificationsCount')
    }
  }

  async handleShowNotifications(): Promise<void> {
    this.showNotifications = !this.showNotifications

    await this.handleFilteredNotificationsGet()
    await this.readNotifications()
  }

  async handleFilteredNotificationsGet(): Promise<void> {
    this.notificationsLoading = true

    const filters = Object.assign({}, this.filter, {
      dateFrom: this.filter.dateFrom
        ? this.filter.dateFrom.replace(/-/g, '.')
        : undefined,
      dateTo: this.filter.dateTo
        ? this.filter.dateTo.replace(/-/g, '.')
        : undefined,
    })

    await this.$store.dispatch('notifications/getNotifications', filters)

    this.notificationsLoading = false
  }

  async mounted(): Promise<void> {
    if (
      this.$route.name !== 'Login' &&
      this.$route.name !== 'ChangePassword' &&
      this.$route.name !== 'RecoveryPassword'
    ) {
      await this.$store.dispatch('notifications/getNotificationsCount')
    }
  }
}
