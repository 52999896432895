import { AxiosRequestConfig } from 'axios'
import { AxiosResponse, AxiosService } from '@/plugins/axios'

export default class LogoutApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL: '/logout',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async logout(): Promise<AxiosResponse<string>> {
    return this.axiosCall<string>({
      method: 'get',
    })
  }
}

export const logoutAPI = new LogoutApiService()
