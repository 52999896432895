import {
  Module,
  VuexModule,
  Action,
  MutationAction,
} from 'vuex-module-decorators'
import { usersAPI } from '@/api/users.api'
import { UserChangeType } from '@/types/user'
import { UsersListItemType } from '@/types/users'
import { PayloadListType } from '@/types/general'

export type LoginBodyType = {
  login: string
  password: string
}

@Module({ stateFactory: true, namespaced: true })
export default class UsersModule extends VuexModule {
  _list: UsersListItemType[] = []
  _total = 0
  _selected = 0
  _detail: UsersListItemType | Record<string, any> = {}

  get usersList() {
    return this._list
  }

  get usersTotal() {
    return this._total
  }

  get selectedUsers() {
    return this._selected
  }

  get usersDetail() {
    return this._detail
  }

  @MutationAction({
    mutate: ['_list', '_total', '_selected'],
  })
  async getUsersList(payload: PayloadListType) {
    const [error, data] = await usersAPI.usersList(payload)

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
        _selected: data.SELECTED,
      }
    } else {
      console.log(error)
      return {
        _list: [],
        _total: 0,
        _selected: 0,
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getUsersDetail(id: number) {
    const [error, data] = await usersAPI.usersDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      console.log(error)
      return {
        _detail: {},
      }
    }
  }

  @Action
  async changeUser(data: UserChangeType) {
    const [error, response] = await usersAPI.usersChange(data)

    if (!error && response) {
      console.log(response)
    } else {
      console.log(error)
    }
  }
}
