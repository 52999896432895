import axios, { Axios, AxiosRequestConfig } from 'axios'
import { Message } from 'element-ui'
import router from '@/router'

export type AxiosResponse<T> = [null, T] | [Error]

export class AxiosService extends Axios {
  constructor(conf: AxiosRequestConfig) {
    super()

    const service = axios.create({
      ...conf,
      withCredentials: true,
    })
    service.interceptors.request.use(
      (config) => {
        config.params = config.params
          ? Object.fromEntries(
              Object.entries(config.params).filter(
                ([_, param]) =>
                  param !== '' && param !== undefined && param !== null
              )
            )
          : {}
        return config
      },
      (error) => {
        return Promise.reject(error)
      }
    )

    service.interceptors.response.use(
      (response) => {
        return response
      },
      (error) => {
        const response = error?.response?.data
        switch (error?.response?.status) {
          case 400:
            Message({
              message: response.error.replaceAll('<br>', ''),
              type: 'error',
              duration: 3000,
              showClose: true,
            })

            break
          case 401:
            break
          case 403:
            if (response.error) {
              if (
                router.currentRoute.name === null ||
                router.currentRoute.name === 'Login'
              ) {
                break
              }

              Message({
                message: response.error,
                type: 'error',
                duration: 3000,
                showClose: true,
              })
              break
            }

            Message({
              message: response.replace('<br>', ''),
              type: 'error',
              duration: 3000,
              showClose: true,
            })

            break
          case 404:
            break
          case 422:
            break
          default:
            break
        }

        return Promise.reject(response)
      }
    )

    return service
  }
}
