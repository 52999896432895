import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import { MembersListItemType, MembersListType } from '@/types/members'
import { TableExportType } from '@/types/general'
import { MemberInfoType } from '@/types/tasks'
import { SelectType } from '@/types/promotions'

export default class MembersApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/remoteusers'
          : '/api/remoteusers',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async membersList(
    limit?: number,
    offset?: number,
    filter?: Record<string, any>,
    order?: object,
    isTop?: number,
    topLimit?: number
  ): Promise<AxiosResponse<MembersListType>> {
    const params = JSON.stringify({
      limit,
      offset,
      filter,
      order,
    })

    const query = {
      isTop,
      topLimit,
    }
    return await this.axiosCall<MembersListType>({
      method: 'get',
      url: `/query_full/${params}`,
      params: query,
    })
  }

  async membersDetail(id: number): Promise<AxiosResponse<MembersListItemType>> {
    return await this.axiosCall<MembersListItemType>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }

  async membersExport(
    filter?: Record<string, any>,
    order?: object,
    isTop?: number,
    topLimit?: number
  ): Promise<AxiosResponse<TableExportType>> {
    const params = JSON.stringify({
      filter,
      order,
    })

    const query = {
      isTop,
      topLimit,
    }
    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `/table/export/${params}`,
      params: query,
    })
  }

  async memberExport(id: number): Promise<AxiosResponse<TableExportType>> {
    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `/table/exportCard/${id}`,
    })
  }

  async getMember(login: string): Promise<AxiosResponse<MemberInfoType>> {
    return await this.axiosCall<MemberInfoType>({
      method: 'get',
      url: `/query/${login}`,
    })
  }

  async getUser(data: string): Promise<AxiosResponse<SelectType[]>> {
    return this.axiosCall<SelectType[]>({
      method: 'post',
      url: '/user',
      data: {
        filter: data,
      },
    })
  }
}

export const membersAPI = new MembersApiService()
