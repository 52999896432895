import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import { TableExportType, PayloadListType } from '@/types/general'
import { OrdersListItemType, OrdersListType } from '@/types/orders'

export default class OrdersApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/order'
          : '/api/order',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async ordersList(
    params: PayloadListType
  ): Promise<AxiosResponse<OrdersListType>> {
    return await this.axiosCall<OrdersListType>({
      method: 'get',
      url: `/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async ordersDetail(id: number): Promise<AxiosResponse<OrdersListItemType>> {
    return await this.axiosCall<OrdersListItemType>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }

  async ordersExport(
    params: PayloadListType
  ): Promise<AxiosResponse<TableExportType>> {
    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `/table/export/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }
}

export const ordersAPI = new OrdersApiService()
