import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import {
  ArtnumbersType,
  RegionsType,
  SalesChartDataType,
  SalesParamsDateType,
  ShopsType,
  StatisticInfoType,
  TopMembersType,
} from '@/types/statistic'
import { TopParamsType } from '@/types/commmon.types'

export default class StatisticApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/statistics'
          : '/api/statistics',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async statisticInfo(): Promise<AxiosResponse<StatisticInfoType>> {
    return await this.axiosCall<StatisticInfoType>({
      method: 'get',
      url: '/getAll/',
    })
  }

  async getTopRegions(): Promise<AxiosResponse<RegionsType[]>> {
    return await this.axiosCall<RegionsType[]>({
      method: 'get',
      url: 'topRegions',
    })
  }

  async getTopUsers(
    params?: TopParamsType
  ): Promise<AxiosResponse<TopMembersType>> {
    return await this.axiosCall<TopMembersType>({
      method: 'get',
      url: 'topUsers',
      params,
    })
  }

  async getTopShops(params?: TopParamsType): Promise<AxiosResponse<ShopsType>> {
    return await this.axiosCall<ShopsType>({
      method: 'get',
      url: 'topShops',
      params,
    })
  }

  async getTopArtnumbers(): Promise<AxiosResponse<ArtnumbersType[]>> {
    return await this.axiosCall<ArtnumbersType[]>({
      method: 'get',
      url: 'topArtnumbers',
    })
  }

  async getSales(
    type: string,
    dates: SalesParamsDateType
  ): Promise<AxiosResponse<SalesChartDataType[]>> {
    return await this.axiosCall<SalesChartDataType[]>({
      method: 'get',
      url: `sales/${type}`,
      params: dates,
    })
  }

  async getPeriodSales(
    type: string,
    period: string
  ): Promise<AxiosResponse<SalesChartDataType[]>> {
    return await this.axiosCall<SalesChartDataType[]>({
      method: 'get',
      url: `sales/${type}/${period}/`,
    })
  }
}

export const statisticAPI = new StatisticApiService()
