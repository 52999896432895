import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import Layout from '@/layout/Layout.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    name: 'Main',
    component: Layout,
    redirect: { name: 'Statistic' },
    children: [
      {
        path: 'login',
        name: 'Login',
        component: () => import('@/pages/Login.vue'),
      },
      {
        path: 'recovery-password',
        name: 'RecoveryPassword',
        component: () => import('@/pages/RecoveryPassword.vue'),
      },
      {
        path: 'change-password',
        name: 'ChangePassword',
        component: () => import('@/pages/ChangePassword.vue'),
      },
      {
        path: 'codes',
        name: 'Codes',
        component: () => import('@/pages/Codes.vue'),
        props: true,
      },
      {
        path: 'members',
        name: 'Members',
        component: () => import('@/pages/Members.vue'),
      },
      {
        path: 'shops',
        name: 'Shops',
        component: () => import('@/pages/Shops.vue'),
      },
      {
        path: 'tasks',
        name: 'Tasks',
        component: () => import('@/pages/Tasks.vue'),
      },
      {
        path: 'statistic',
        name: 'Statistic',
        component: () => import('@/pages/Statistics.vue'),
      },
      {
        path: 'profile',
        name: 'Profile',
        component: () => import('@/pages/Profile.vue'),
      },
      {
        path: 'promotions',
        name: 'Promotions',
        component: () => import('@/pages/Promotions.vue'),
      },
      {
        path: 'vendors',
        name: 'Vendors',
        component: () => import('@/pages/Vendors.vue'),
      },
      {
        path: 'orders',
        name: 'Orders',
        component: () => import('@/pages/Orders.vue'),
      },
      {
        path: 'member-statistic/:memberId',
        name: 'MemberStatistic',
        component: () => import('@/pages/MemberDetail.vue'),
      },
      {
        path: 'transactions',
        name: 'Transactions',
        component: () => import('@/pages/Transactions.vue'),
      },
      {
        path: 'logs',
        name: 'Logs',
        component: () => import('@/pages/Logs.vue'),
      },
    ],
  },
  {
    path: '/create-codes',
    name: 'CreateCodes',
    component: () => import('@/pages/CreateCodes.vue'),
  },
  {
    path: '/activate',
    name: 'Activate',
    component: () => import('@/pages/Activate.vue'),
  },
]

const router = new VueRouter({
  mode: 'history',
  base: '/',
  routes,
})

export default router
