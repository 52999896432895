import { AxiosRequestConfig } from 'axios'
import { AxiosService, AxiosResponse } from '@/plugins/axios'
import { TableExportType, PayloadListType } from '@/types/general'
import { AccrualsListItemType, AccrualsListType } from '@/types/accruals'

export default class AccrualsApiService {
  private axiosInstance: AxiosService

  constructor() {
    this.axiosInstance = new AxiosService({
      baseURL:
        process.env.NODE_ENV === 'production'
          ? process.env.VUE_APP_API_BASE_URI + '/accrual'
          : '/api/accrual',
    })
  }

  private async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<AxiosResponse<T>> {
    try {
      const { data } = await this.axiosInstance.request<T>(config)
      return [null, data]
    } catch (error: any) {
      return [error]
    }
  }

  async accrualsList(
    params: PayloadListType
  ): Promise<AxiosResponse<AccrualsListType>> {
    return await this.axiosCall<AccrualsListType>({
      method: 'get',
      url: `/query_full/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }

  async accrualsDetail(
    id: number
  ): Promise<AxiosResponse<AccrualsListItemType>> {
    return await this.axiosCall<AccrualsListItemType>({
      method: 'get',
      url: `/detail/${id}`,
    })
  }

  async accrualsExport(
    params: PayloadListType
  ): Promise<AxiosResponse<TableExportType>> {
    return await this.axiosCall<TableExportType>({
      method: 'post',
      url: `/table/export/${
        JSON.stringify(params) ? JSON.stringify(params) : ''
      }`,
    })
  }
}

export const accrualsAPI = new AccrualsApiService()
