




















import { Component, Vue, Model, Prop } from 'vue-property-decorator'

@Component
export default class Drawer extends Vue {
  @Model('change', { type: Boolean })
  value!: boolean

  @Prop({ type: String })
  title?: String

  handleDrawerClose(): void {
    this.$emit('change', false)
  }
}
