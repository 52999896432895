import { Module, VuexModule, MutationAction } from 'vuex-module-decorators'
import { PayloadListType, TableExportType } from '@/types/general'
import { LogsListItemType } from '@/types/logs'
import { logsAPI } from '@/api/logs.api'

@Module({ stateFactory: true, namespaced: true })
export default class LogsModule extends VuexModule {
  _list: LogsListItemType[] = []
  _total = 0
  _detail: LogsListItemType | Record<string, any> = {}
  _exportLogs: TableExportType | Record<string, any> = {}

  get logsList() {
    return this._list
  }

  get logsTotal() {
    return this._total
  }

  get logsDetail() {
    return this._detail
  }

  get exportLogs() {
    return this._exportLogs
  }

  @MutationAction({
    mutate: ['_list', '_total'],
  })
  async getLogsList(payload: PayloadListType) {
    const [error, data] = await logsAPI.logsList(payload)

    if (!error && data) {
      return {
        _list: data.items,
        _total: data.count,
      }
    } else {
      console.log(error)
      return {
        _list: [],
        _total: 0,
      }
    }
  }

  @MutationAction({
    mutate: ['_detail'],
  })
  async getLogsDetail(id: number) {
    const [error, data] = await logsAPI.logsDetail(id)

    if (!error && data) {
      return {
        _detail: data,
      }
    } else {
      console.log(error)
      return {
        _detail: {},
      }
    }
  }

  @MutationAction({
    mutate: ['_exportLogs'],
  })
  async getExportLogs() {
    const [error, data] = await logsAPI.logsExport()

    if (!error && data) {
      return {
        _exportLogs: data,
      }
    } else {
      console.log(error)
      return {
        _exportLogs: {},
      }
    }
  }
}
