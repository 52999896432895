
















import { Component, Vue } from 'vue-property-decorator'

@Component
export default class HeaderMobile extends Vue {
  get notificationsCount(): number {
    return this.$store.getters['notifications/notificationsTotalCount']
  }

  handleShowNotifications(): void {
    this.$emit('show-notifications')
  }
}
